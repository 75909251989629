.list-block {
  height: 100%;
  max-height: max-content;
  padding-bottom: 5px;
  overflow-y: scroll;

  &_full-height {
    padding-bottom: 10px;
  }
  
  &__list {
    column-gap: 30px;
    columns: 2 auto;
  }
}

.content {
  position: relative;

  &_long {
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-template-columns: 100%;
    padding-bottom: 0;
  }
}

.card {
  overflow: hidden;

  &_full-screen {
    position: fixed;
    height: initial;
    margin: 0;
    border-radius: initial;
  }

  &_transition-end {
    z-index: 1;
  }

  &__full-screen-button {
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--color-black);
    border-radius: 6px;
    opacity: 1;
    transition: opacity .3s ease-out;

    & > svg {
      width: 15px;
      height: 15px;
    }
  }
}

.skeleton {
  position: absolute;
  top: 20px;
  width:  100%;
  height: calc(100% - 20px);
}

.timeline-wrapper {
  height: 100%;
  min-height: 250px;
}

.timeline {
  height: calc(100% - 20px);
}

.resize-handler {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  border-bottom: solid 1px var(--color-black);

  &__btn {
    width: 30px;
    height: 10px;
    background-color: var(--color-white);
  }

  &__icon {
    transform: translateY(-7px);
  }
}
