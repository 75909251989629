.search-block {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 55px;
  gap: 10px;
  padding: 10px 20px;

  &::before {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    content: '';
    border-bottom: 1px solid var(--separator-light-color);
  }

  &__search-trigger {
    --button-hover-background-color: var(--color-yellow-60) !important;
    --button-hover-border-color: var(--color-black) !important;
    --button-hover-color: var(--color-black) !important;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    color: var(--color-gray-80);
    cursor: pointer;
    background: var(--color-white);
    border: 2px solid currentcolor;
    border-radius: 10px;

    &:hover:enabled {
      color: var(--color-black);
    }

    &:disabled {
      pointer-events: all;
      cursor: not-allowed;
    }

    &_cleanup {
      --cross-opacity: 1;
      --search-opacity: 0;
      color: var(--color-black);
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      content: '';
      border-bottom: 2px solid currentcolor;
      opacity: var(--cross-opacity, 0);
      translate: -50% -50%;
    }

    &::before {
      rotate: 45deg;
    }

    &::after {
      rotate: -45deg;
    }
  }

  &__search-icon {
    width: 55px;
    height: 55px;
    opacity: var(--search-opacity, 1);
  }
}
