.confirmation-modal {
  --modal-min-width: 610px;
  --modal-max-width: 95vw;
  --modal-max-height: 95vw;

  width: min(var(--modal-min-width), var(--modal-max-width));
  height: min(var(--modal-min-height), var(--modal-max-height));
  min-height: var(--modal-min-height);
  border-radius: 20px;

  &__header {
    padding-bottom: 0;
  }

  &__attention,
  &__content {
    display: flex;
    column-gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-inline: 40px;
  }

  &__attention-icon {
    flex-shrink: 0;
  }

  &__content {
    flex-direction: column;
    padding-inline: 0;
  }

  &__content-text {
    padding-bottom: 20px;
  }

  &__button {
    font-size: 17px;
    font-weight: normal;
    padding-inline: 30px;

    & button {
      height: 45px;
    }
  }

  &__footer {
    display: flex;
    column-gap: 5px;
    justify-content: flex-end;
  }
}
