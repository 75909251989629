.filter-block {
  position: relative;
  display: grid;
  grid-template-columns: var(--css-grid-layout) 55px;
  gap: 10px;
  padding: 10px 20px;

  &::before {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    content: '';
    border-bottom: 1px solid var(--color-black);
  }

  &__trigger {
    --button-hover-background-color: var(--color-yellow-60) !important;
    --button-hover-border-color: var(--color-black) !important;
    --button-hover-color: var(--color-black) !important;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    padding: 0;
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-black);
    border: 2px solid var(--color-black);
    border-radius: 10px;

    &:hover:enabled {
      color: var(--color-black);
    }

    &:disabled {
      color: var(--color-gray-80);
      pointer-events: all;
      cursor: not-allowed;
      background-color: var(--color-white);
      border-color: var(--color-gray-80);
      opacity: 1;
    }

    &_cleanup {
      --cross-opacity: 1;
      --search-opacity: 0;
      color: var(--color-black);
      background: var(--color-white);
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      content: '';
      border-bottom: 2px solid currentcolor;
      opacity: var(--cross-opacity, 0);
      translate: -50% -50%;
    }

    &::before {
      rotate: 45deg;
    }

    &::after {
      rotate: -45deg;
    }
  }

  &__trigger-icon {
    width: 55px;
    height: 55px;
    opacity: var(--search-opacity, 1);
  }
}


.filter-input {
  &__input {
    --input-padding: 0 15px;

    height: 55px;
    font-size: 20px;
    font-weight: normal;
    background: var(--color-white-15);
    border: none;
    border-radius: 10px;

    &::placeholder {
      color: var(--color-gray-70);
      opacity: 1;
    }
  }
}
