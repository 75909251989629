.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 20px;
    font-weight: normal;
    color: var(--color-black);

    :global(._disabled) & {
      color: var(--color-gray-20);
      opacity: 0.7;
    }
  }
}
