.reagents-modal {
  bottom: 20px;
  width: 50%;
  min-width: 950px;
  height: auto;
  max-height: none;
  padding: 0;

  &__header {
    position: relative;
    padding: 19px 20px 20px;
    border-bottom: none;

    &::before {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--color-black-20)
    }
  }

  &__header-text {
    font-size: 20px;
    font-weight: normal;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 76px;
    border: 7px solid var(--color-black);
    border-radius: 50%;
  }

  &__info-icon {
    width: 34px;
    height: 34px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

    &._hidden {
      display: none;
    }
  }

  &__scrollable-block {
    display: grid;
    flex-grow: 1;
    padding-right: 18px;
    margin-right: 2px;
    margin-left: 20px;
    overflow: auto;
  }

  &__footer {
    position: relative;
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    padding: 20px;

    &::before {
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
      content: '';
      border-top: 1px solid var(--color-black-20);
    }
  }

  &__button-group {
    display: flex;
    gap: 10px;
  }

  &__button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    letter-spacing: .1px;
    white-space: nowrap;
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;
    padding-inline: 19px;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--color-black);
      pointer-events: all;
      cursor: not-allowed;
      background-color: transparent;
      box-shadow: 0 0 0 1px var(--color-gray-80);
      opacity: 1;
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }

  &__add {
    width: 290px;
    font-weight: 600;
    background-color: var(--color-yellow-60);
    box-shadow: inset 0 0 0 1px transparent;
  }

  &__custom {
    display: flex;
    gap: 14px;
    width: max-content;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--color-black);

    & > svg {
      flex-shrink: 0;
    }
  }

  &__close {
    width: 145px;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--color-black);
  }
}
