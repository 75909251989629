.lanes-block {
  padding-bottom: 15px;
}

.switch-container {
  width: fit-content;
  height: 55px;

  &__item {
    --checkbox-border-color-disabled: var(--color-gray-70);
    --checkbox-color-slider-disabled: var(--color-white);
    --checkbox-color-slider-checked-disabled: var(--color-white);
    --checkbox-color-switch-checked-disabled: var(--color-white);

    height: 30px;
  }
}
