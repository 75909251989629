@import '@/styles/variables';

.card {
  --bottom-part-height: 93px;
  --right-part-width: clamp(100px, 25%, 130px);
  --card-border-color: var(--color-gray-80);
  --draft-card-border-color: var(--color-black-20);
  --h-separator-color: var(--color-black-20);
  --v-separator-color: var(--card-border-color);
  --hover-image-color: var(--color-yellow-60);

  position: relative;
  display: grid;
  grid-template-areas: 'title aside' 'assay-list aside' 'meta meta';
  grid-template-rows: 1fr 70px var(--bottom-part-height);
  grid-template-columns: 1fr var(--right-part-width);
  gap: 10px 0;
  min-height: 300px;
  overflow: hidden;
  border-radius: 20px;

  &_draft {
    grid-template-areas: 'title aside' 'assay-list assay-list' 'meta meta';
    border: none;
  }

  &:hover {
    --hover-image-scale: 1;
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &__separator-top {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
      height: 0;
      content: '';
      border-top: 1px solid var(--h-separator-color);
    }
  }

  &__separator-left {
    position: relative;

    &::before {
      position: absolute;
      top: 20px;
      bottom: 0;
      left: 0;
      width: 0;
      content: '';
      border-left: 1px solid var(--v-separator-color);
    }
  }

  &__title-wrap {
    padding-top: 20px;
    padding-left: 20px;
  }

  &__title {
    --line-count: 2;
    --font-size: 33px;
    --line-height: 1.1;

    position: relative;
    display: -webkit-box;
    grid-area: title;
    height: calc(var(--line-count) * var(--font-size) * var(--line-height) + 3px);
    padding-right: 20px;
    overflow: hidden;
    font-size: var(--font-size);
    font-weight: 600;
    line-height: var(--line-height);
    color: var(--color-black);
    letter-spacing: -.5px;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-count);
  }

  &__assay-list-wrap {
    position: relative;
    top: -5px;
    display: flex;
    grid-area: assay-list;
    align-items: flex-start;
    padding-top: 5px;
    margin-left: 20px;
    overflow: auto hidden;
  }

  &_draft &__assay-list-wrap {
    margin-right: 20px;
  }

  &__assay-list {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    grid-area: aside;
    justify-content: space-between;
    padding-top: 20px;
    padding-inline: 20px;
  }

  &__template-sign {
    position: relative;
    width: 35px;
    height: 35px;
    border-top: 7px solid var(--color-black);

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      content: '';
      border-right: 7px solid var(--color-black);
      translate: -50%;
    }
  }

  &__draft-step {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 70px;
    height: 70px;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: .25px;
    border: 1.5px solid var(--color-black);
    border-radius: 50%;
  }

  &__ready-step-count {
    font-size: 50px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: -.5px;
  }

  &__ready-step-text {
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -.25px;
  }

  &__meta-wrap {
    grid-area: meta;
    padding: 15px 20px 20px;

    &_over-hover {
      z-index: 1;
    }
  }

  &__meta {
    z-index: 10;
    width: 100%;
    height: max-content;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--color-black);
    letter-spacing: -.25px;
  }

  &__meta-row {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 20px;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__hover-content {
    position: absolute;
    inset: 1px;
    border: 1px solid var(--card-border-color);
    border-radius: 20px;
  }

  &_draft &__hover-content {
    border: 1px dashed var(--draft-card-border-color);
  }

  &__hover-image {
    position: absolute;
    top: calc(var(--top, 0) * 1px);
    left: calc(var(--left, 0) * 1px);
    width: 250%;
    pointer-events: none;
    background: radial-gradient(circle at center, var(--hover-image-color) 50%, transparent 70%) transparent;
    border-radius: 50%;
    transition: scale .3s ease-in, background .1s ease-out;
    translate: -50% -50%;
    scale: var(--hover-image-scale, 0);
    aspect-ratio: 1;
  }

  &:hover &__hover-image {
    background: radial-gradient(circle at center, var(--hover-image-color) 50%, transparent 70%) var(--hover-image-color);
    transition: scale .3s ease-in, background .2s .3s ease-out;
  }

  &__control-list-wrap {
    --bg-image-width: 0%;

    position: absolute;
    inset: 0;
    clip-path: circle(var(--bg-image-width) at calc(var(--left, 0) * 1px) calc(var(--top, 0) * 1px));
    transition: clip-path .3s ease-out;
    will-change: clip-path;
  }

  &:hover &__control-list-wrap {
    --bg-image-width: 250%;
    transition: clip-path .4s ease-in;
  }

  &__control-list {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 20px 20px;
  }

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 20px;
    font-weight: normal;
    color: var(--color-black);
    letter-spacing: .25px;
    border: 1.5px solid var(--color-black);
    border-radius: 100px;

    &_disabled {
      pointer-events: none;
      opacity: .6;
    }
  }

  &__control-icon {
    position: absolute;
    top: 50%;
    left: 30px;
    translate: 0 -50%;
    line-height: 1;

    svg {
      width: 1em;
      height: auto;
      vertical-align: middle;
    }
  }
}
