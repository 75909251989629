.run-details {
  &__header {
    display: flex;
    border-bottom: 1px solid var(--light-color-gray-10);
  }

  &__title {
    --line-count: 2;
    --font-size: 50px;
    --line-height: 1.1;
    --padding-top: 10px;

    position: relative;
    display: -webkit-box;
    flex-grow: 1;
    grid-area: title;
    height: calc(var(--line-count) * var(--font-size) * var(--line-height) + 3px + var(--padding-top)) ;
    padding-top: var(--padding-top);
    padding-right: 20px;
    overflow: hidden;
    font-size: var(--font-size);
    font-weight: 600;
    line-height: var(--line-height);
    color: var(--color-black);
    letter-spacing: -1px;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-count);
  }

  &__header-data {
    display: grid;
    flex-shrink: 0;
    grid-template-columns: repeat(var(--module-count, 1), 1fr) minmax(20%, 550px);
  }

  &__header-data:has(&__module:nth-of-type(2) + &__meta) {
    --module-count: 2;
  }

  &__header-data:has(&__module:nth-of-type(3) + &__meta) {
    --module-count: 3;
  }

  &__header-data:has(&__module:nth-of-type(4) + &__meta) {
    --module-count: 4;
  }

  &__module::before,
  &__meta::before {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    width: 0;
    content: '';
    border-left: 1px solid var(--color-black);
  }

  &__module {
    position: relative;
    min-width: 220px;
  }

  &__meta {
    --metadata-grid-template-columns: 100px 1fr;

    position: relative;
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  &__meta-title {
    font-size: 20px;
    letter-spacing: .25px;

  }

  &__action {
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      content: '';
      border-bottom: 1px dashed var(--color-black);
    }
  }

  &__meta-description {
    overflow: hidden;
    font-size: 20px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
