@keyframes backdrop-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.dialog-modal {
  --inset: 20px;
  --separator-color: var(--color-black-20);

  --view-animation-duration: .5s;
  --view-animation-offset: 30px;
  --view-timing-function: ease-out;

  inset: var(--inset);
  display: grid;
  grid-template-rows: 70px 1fr;
  width: auto;
  height: auto;
  padding: 0;
  pointer-events: none;
  border: none;
  opacity: 0;
  translate: 0 var(--view-animation-offset);
  scale: 0;
  transition: opacity var(--view-animation-duration) var(--view-timing-function),
  translate var(--view-animation-duration) ease-out;

  &::backdrop {
    background-color: rgba(0, 0, 0, .5);
  }

  &[open] {
    pointer-events: all;
    opacity: 1;
    translate: 0 0;
    scale: 1;

    &::backdrop {
      animation: backdrop-in var(--view-animation-duration) var(--view-timing-function) forwards;
    }
  }

  &._closing {
    --view-timing-function: ease-in;

    opacity: 0;
    translate: 0 var(--view-animation-offset);

    &::backdrop {
      animation: backdrop-out var(--view-animation-duration) var(--view-timing-function) forwards;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-inline: 20px;

    &::before {
      position: absolute;
      top: 100%;
      right: 20px;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--separator-color);
    }
  }

  &__title {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: .25px;
  }

  &__close {
    --button-hover-color: var(--color-gray) !important;

    width: 24px;
    height: 24px;
    padding: 0;
    color: var(--color-black);
    border-radius: 4px;
    translate: 4px;
  }

  &__content {
    display: grid;
    padding: 0;
    overflow: hidden;
  }
}
