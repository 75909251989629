.switch {
  display: grid;
  grid-template-columns: 20px 50px 30px;
  column-gap: 10px;
  border-radius: 50%;

  &__control {
    width: 55px;
    background-color: var(--color-black);
  }
}

.switch-input {
  &_changed {
    --checkbox-color-slider-checked: var(--color-yellow-60);
    --checkbox-color-switch-checked: var(--color-black);
  }
}
