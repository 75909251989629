.details {
  font-size: 20px;

  &__header {
    --metadata-offset: 20px;

    &._collapsed {

      --metadata-offset: 8px;
    }
  }

  &__metadata-wrap {
    position: absolute;
    top: var(--metadata-offset);
    right: 0;
    bottom: var(--metadata-offset);
    z-index: 2;
    width: 30%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: opacity .8s ease-out, clip-path .5s ease-out, top .3s ease-out, bottom .3s ease-out;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0;
      content: "";
      border-left: 1px solid var(--color-black-20);
    }

    &._loading {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      opacity: 0;
    }
  }

  &__metadata {
    padding-inline: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 20px;
    line-height: normal;
    scrollbar-gutter: stable;
  }

  &__metadata-item {
    padding-bottom: 10px;

    &:first-child {
      margin-top: -7px;
    }
  }

  &__metadata-title {
    font-weight: normal;
  }

  &__panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 60px;
    padding: 20px 0 0;

    &_design-name {
      height: 110px;
      padding: 0 0 20px;
      border-bottom: solid 1px var(--separator-color);
    }
  }

  &__group {
    display: grid;
    grid-template-columns: 175px 1fr;
    row-gap: 20px;
    column-gap: 10px;
    align-content: flex-start;
  }

  label {
    padding: 14px 0;
  }

  &__name-label {
    font-weight: 600;
  }

  &__value {
    min-width: 400px;
  }

  &__input {
    --input-bg-color: var(--color-white-15);
    --input-border-width: 1px;
    --input-border-color: var(--color-white-15);
    --input-border-color-focus: var(--color-white-15);
    --input-font-size: 20px;
    --input-padding: 14px;
    --input-height: 55px;
    --input-font-weight: 400;
    border-radius: 10px;

    &_error {
      --input-border-color: var(--color-red-error);
      --input-border-color-focus: var(--color-red-error);
    }

    & input:disabled {
      --input-border-color: var(--color-white-15);
    }

    &:has(input:focus-visible) {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__textarea-wrapper {
    --textarea-bg-color: var(--color-white-15);
    --textarea-border-width: 0;
    --textarea-padding: 15px;
    --textarea-border-radius: 10px;
    --textarea-font-size: 20px;
  }

  &__textarea {
    height: 100%;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__select {
    --select-bg-color: var(--color-white-15);
    --select-border-width: 1px;
    --select-border-color: var(--color-white-15);
    --select-font-size: 20px;

    &_error {
      --select-border-color: var(--color-red-error);
    }
  }

  &__select-control {
    height: 55px;
    padding: 0 5px;
  }

  &__select-menu {
    --select-menu-margin-block: -55px;
  }

  &__add-button-wrap {
    grid-column-start: 2;
  }

  &__add-button {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 6px;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__plus-icon {
    width: 20px;
    height: 20px;
  }

  &__multi-value {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_item {
      --organism-padding-right: 40px;

      position: relative;
      display: block;}
  }

  &__organism {
    position: relative;
    padding-right: var(--organism-padding-right, 0);
    background-color: var(--color-white-15);
    border-radius: 10px;
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    border: 2px solid transparent;
    border-radius: 0 10px 10px 0;
    transition: border-color .3s ease-out, background-color .3s ease-out;

    &:hover,
    &:active {
      background-color: var(--color-yellow-60);
      border-color: var(--color-black-10);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__delete-icon {
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
  }
}

.required {
  color: var(--color-red-required);
}
