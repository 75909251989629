.card {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;

  &_one-row {
    grid-template-rows: 1fr;
  }

  &__header {
    display: flex;
    width: 100%;
    height: 55px;
  }

  &__content {
    flex-grow: 1;
  }
}
