.content {
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  background-color: var(--color-white-15);

  &_clickable {
    cursor: pointer;
  }
}
