@import '@/styles/variables';

.sensor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.25px;
  border-bottom: solid 1px var(--light-color-gray-10);
  transition: border-bottom 0.3s;
  
  &_clickable:hover {
    border-bottom: solid 1px var(--color-black);
  }

  &_disabled {
    pointer-events: none;
    cursor: default;
    opacity: .6;
  }

  &__name {
    align-self: baseline;
    width: max-content;
    color: var(--light-gray);
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;

    &_dashed {
      text-decoration-style: dashed;    
      cursor: default;
    }

    &_selected {
      font-weight: bold;
      color: var(--color-black);
      text-decoration: none;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__time {
    padding-right: 5px;
    font-size: 0.8em;
    line-height: 1.6;
    color: var(--light-gray);
    text-align: right;
  }
}
