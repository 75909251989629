.card {
  --heading-height: clamp(100px, calc(35cqh - 30px), 225px);
  --action-section-height: 90px;
  --bg-image-translate-duration: .3s;
  --design-card-bg-color: #FFF385;
  --image-main-color: #dce5fa;
  --cta-bg-color: var(--color-yellow-40);
  --cta-color: var(--color-black);

  overflow: hidden;
  text-align: left;
  border: none;
  border-radius: 20px;
  container-type: size;

  &__wrap {
    display: grid;
    grid-template-rows: var(--heading-height) repeat(2, 1fr) var(--action-section-height);
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 100%;
  }

  &._separated &__wrap {
    --is-separated: 1;
    grid-template-areas:  'heading heading'
                          'content content'
                          'content content'
                          'action action';
    background-color: var(--color-white);
  }

  &._union &__wrap {
    --is-union: 1;
    --cta-bg-color: var(--color-black);
    --cta-color: var(--color-white);
    --image-main-color: var(--design-card-bg-color);

    grid-template-areas:  'heading heading'
                          'heading heading'
                          'content content'
                          'action action';
    background-color: var(--design-card-bg-color);
  }

  &__wrap:hover {
    --bg-image-scale: 1.1;
    --bg-image-translate: 10px;
    --bg-image-translate-duration: 1s;
  }

  &__heading {
    position: relative;
    grid-area: heading;
    padding: 20px;
    font-size: 45px;
    font-weight: bold;
    line-height: 55px;
    letter-spacing: -1px;
  }

  &__heading-slash {
    display: var(--is-separated, none);
    padding-right: 5px;
  }

  &__heading-arrow {
    display: var(--is-union, none);
  }

  &__bg-image-wrap {
    position: relative;
    display: flex;
    grid-area: heading;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
    background-color: var(--image-main-color);
  }

  &__bg-image {
    position: absolute;
    min-height: 100%;
    transition: scale .3s ease-out, translate var(--bg-image-translate-duration) ease-in-out;
    transform-origin: 90% 20%;
    scale: var(--bg-image-scale, 1);
    translate: var(--bg-image-translate, 0) 0;
    object-fit: cover;
  }

  &__data {
    height: 100%;
    padding-inline: 20px;

    &_with-right-separator {
      position: relative;

      &::before {
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 100%;
        width: 0;
        content: '';
        border-right: 1px solid var(--color-black-20);
        opacity: .9;
      }
    }

    &_with-top-separator {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        right: 20px;
        left: 20px;
        height: 0;
        content: '';
        border-top: 1px solid var(--color-black-20);
        opacity: .9;
      }
    }
  }

  &__row {
    display: flex;
    grid-column-start: 1;
    grid-column-end: -1;
    padding-bottom: 5px;
  }

  &__counter {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 155px;
    font-size: 135px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -5px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 16px;
  }

  &__status {
    font-size: 20px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: .25px;
  }

  &__last {
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__counter-with-comment {
    display: flex;
    flex-direction: column;
  }

  &__mini-counter-wrap {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    height: 100%;
    max-height: 100px;
  }

  &__radial-loader {
    --theme-loader-radius-outer: 20px;
    --theme-loader-radius-inner: 10px;
    --loader-color-below: transparent;
    --loader-color-under: var(--color-black);

    top: -10px;
    left: 4px;
  }

  &__mini-counter {
    font-size: 90px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -5px;
  }

  &__comment {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: .25px;
  }

  &__cta-wrap {
    grid-area: action;
    padding-inline: 20px;
    padding-bottom: 20px;
  }

  &__cta {
    height: 70px;
    font-size: 20px;
    font-weight: bold;
    line-height: 70px;
    color: var(--cta-color);
    text-align: center;
    background-color: var(--cta-bg-color);
    border-radius: 10px;
  }
}
