@import '@/styles/variables';

.sample-type-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 590px;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-white-15);
  border-radius: 10px;
  transition: min-width 0.3s ease-out, width 0.3s ease-out, flex-grow 0.3s ease-out;
  margin-inline: 10px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__head {
    height: 55px;
  }

  &__content {
    @include run-design-card-content;

    display: flex;
    flex-grow: 1;
    overflow-x: auto;
  }

}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: var(--color-white);
  background-color: var(--color-black);
}

.table-head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-white-5);
  margin-inline: 20px;
}
