.suggestion {
  &__input {
    --input-padding: 0 15px;

    height: 55px;
    font-size: 20px;
    font-weight: normal;
    background: var(--color-white-15);
    border: none;
    border-radius: 10px;

    &::placeholder {
      color: var(--color-gray-70);
      opacity: 1;
    }
  }

  &__wrap {
    z-index: var(--z-modal);
    width: 580px;
    background-color: var(--color-white-15);
    border: 1px solid var(--color-gray-80);
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25)
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 20px
  }

  &__elem {
    padding: 10px 12px;
    overflow: hidden;
    font-size: 20px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space:nowrap;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: var(--color-yellow-60);
    }
  }

  &__radio {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
}
