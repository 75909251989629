.channel-selector {
  &:global(._disabled) {
    --select-bg-color: var(--color-white-15);
    --theme-select-color: var(--color-gray-20);
  }

  &:has(&__input:user-invalid) > &__select-wrap {
    --select-bg-color: var(--color-red-50) !important;
    --placeholder-color: var(--color-red-20);
  }

  &__select-wrap {
    --select-bg-color: var(--color-white-15);

    border-radius: 10px;

    &:focus-within {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__select {
    height: 55px;
    background-color: var(--select-bg-color) !important;
    opacity: 1 !important;
  }

  &__menu {
    translate: 0 48px;
    border: none;
  }

  &__list {
    width: calc(100% + 5px);
    padding: 3px 0 !important;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.10) !important;
  }

  &__list-wrap {
    box-shadow: none;
  }

  &__placeholder {
    padding-left: 13px;
    font-size: 20px !important;
    color: var(--placeholder-color, var(--color-gray-70)) !important;
  }

  &__value-wrap {
    padding: 0 !important;
  }

  &__value {
    font-size: 20px !important;
  }

  &__input {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
}
