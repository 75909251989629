.lanes-table {
  --letter-color: var(--color-white);
  --letter-background-color: var(--color-black);
  --letter-border-color: var(--color-black);

  &_non-existent {
    --letter-color: var(--color-white-5);
    --letter-background-color: var(--color-white);
    --letter-border-color: var(--color-white-5);
  }

  &__header-row {
    font-size: 20px;
    box-shadow: 0 4px 1px -3px var(--icons-border-color);
  }

  &__header-item {
    font-weight: normal;
    letter-spacing: 0;
  }

  &__cell {
    --row-size: var(--template-row-size, 55px);

    min-height: var(--row-size);
  }

  &__label{
    &_disabled {
      --letter-color: var(--light-color-gray-10);
      --letter-border-color: var(--light-color-gray-10);
    }
  }
}
