.home {
  --top-block-height: 230px;
  height: 100%;
  padding-top: 20px;

  &__wrap {
    display: grid;
    grid-template-rows: var(--top-block-height) 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    height: 100%;
  }
}
