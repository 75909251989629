@import '@/styles/variables';

.sub-header {
  align-items: center;
  min-height: auto;
  border-bottom: solid 1px var(--color-black-20);
  padding-block: 15px;

  &__title-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: baseline;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    vertical-align: top;
  }

  &__button {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 0;
    border: none;
    box-shadow: none;

    &:hover:enabled,
    &:active:enabled {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
  }

  &__button-substring {
    position: relative;
    font-size: 20px;
    font-weight: normal;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      content: "";
      border-bottom: 1px dashed var(--color-black);
    }

  }

  &__button-icon {
    transform: scale(-1, 1) rotate(-90deg);
  }
}
