@import '@/styles/variables';

.card {
  height: 100%;
}

.header {
  display: flex;
  gap: 60px;
  align-items: baseline;
  width: 100%;
  border-bottom: solid 1px var(--color-black-20);

  &__title {
    @include title-format;
    @include resizeLinearMd(font-size, 22, 26);
  
    padding-bottom: 15px;
    font-size: 26px;
    font-weight: bold;
  }

  &__right-actions {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: auto;
    font-size: 20px;
  }

  &__left-actions {
    position: relative;
    margin-right: auto;
    font-size: 20px;

    &::before {
      position: absolute;
      left: -30px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-black);
    }
  }
}

.content {
  padding-block: 10px;
  height: calc(100% - 40px);
  overflow-y: hidden;
}

.loader {
  @include loader;
  width: max-content;
  height: 100%;
  margin: auto;
  background-color: unset;
  padding-block: 20px;
}

.no-data {
  @include no-data-block;
}
