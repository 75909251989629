.input-container {
  --field-height: 50px;

  position: relative;
  width: 145px;
  height: var(--field-height);

  &__mixed-layout {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 145px;
    height: 100%;
    padding-inline: 15px;
    pointer-events: none;
    background: var(--color-yellow-60);
    border-radius: 10px;
  }

  &__popover {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 2;
    transform: translateY(-50%);
  }
}

.input {
  --background-color: var(--color-white-15);
  --input-border-color: var(--color-white-15);
  --input-border-color-disabled: var(--color-gray-80);
  --input-font-weight: normal;
  --input-height: var(--field-height);
  --input-font-size: 16px;

  width: 145px;
  background-color: var(--background-color);
  border-radius: 10px;

  &_focused {
    position: relative;
  }

  &_changed {
    --background-color: var(--color-yellow-60) !important;
  }

  &_disabled {
    pointer-events: none;
  }

  &_light {
    --background-color: var(--color-white);
  }
}

.error {
  box-shadow: 0 0 2px 1px var(--color-red) !important;
}
