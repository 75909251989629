@import '@/styles/variables';

.popover{
  &__content {
    display: flex;
    flex-direction: column;
    width: 205px;
    height: 74px;
    padding: 10px 20px;
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0 0 20px 0 var(--icons-border-color);
  }

  &__title {
    color: var(--light-gray)
  }
}

.info-btn {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
  border: solid 2px var(--color-gray-70);
  border-radius: 20px;
  transition: background-color .3s ease-out;

  &__icon {
    color: var(--color-gray-70);
    transition: color .3s ease-out;

    &:hover {
      color: var(--color-white);
    }

    
    &:focus-visible {
      border-color: transparent;
      outline: 2px solid var(--focus-color);
    }
  } 

  &:hover {
    background-color: var(--color-black);
    border: solid 1px  var(--color-black);
  }

  &:hover &__icon {
    color: var(--color-white);
  } 

}
