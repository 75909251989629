.input {
  width: 320px;
  height: 55px;
  font-size: 20px;
  font-weight: normal;
  background-color: var(--color-white-15);
  border: none;

  &_disabled {
    background-color: var(--color-white);
    border: 1px solid var(--color-white-5);
  }

  &_locked {
    cursor: not-allowed;
  }
}

.locked-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0%, -50%);
}
