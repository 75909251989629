.channel-option {
  &#{&} {
    display: flex;
    gap: 14px;
    align-items: center;
    padding: 2px 5px;
    font-weight: normal !important;
    color: var(--color-black) !important;
    cursor: pointer;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: var(--color-yellow-60);
      opacity: 1;
    }
  }

  &__text {
    font-size: 18px;
  }
}
