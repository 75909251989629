.reagent-option {
  &__wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 340px;
    padding-left: 12px;
    cursor: pointer;
    border-bottom: solid 1px var(--color-white-5);
    transition: background-color 0.3s ease-out;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: var(--color-yellow-40);
    }
  }

  &__item {
    font-size: 16px;
    background-color: transparent;
  }

  &__waves {
    margin: 0 10px;
  }

  &__type {
    width: 120px;
    font-size: 16px;
  }

  &__check-mark {
    width: 10px;
  }
}
