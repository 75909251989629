.re-selectable-popover {
  width: calc(var(--width, 250) * 1px);
  zoom: var(--layout-zoom);
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  animation: show .3s ease-out;

  @keyframes show {
    from {
      opacity: 0;
      translate: 0 10px;
    }

    to {
      opacity: 1;
      translate: 0;
    }
  }

  &__elem {
    position: relative;
    display: flex;
    width: 100%;

    &::before {
      position: absolute;
      right: 10px;
      bottom: 0;
      left: 10px;
      content: '';
      border-bottom: 1px solid var(--color-gray-80);
    }

    &:last-child::before {
      opacity: 0;
    }
  }

  &__button {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr max-content;
    align-items: center;
    height: 60px;
    padding-right: 37px;
    padding-left: 10px;
    text-align: left;
  }

  &__name {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    max-height: calc(2em * 1.15);
    overflow: hidden;
    line-height: 1.15;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: balance;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 16px;
    color: var(--color-gray-80);
  }
}
