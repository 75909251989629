.add-information {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  color: var(--color-black);
  text-align: center;

  &__icon {
    margin-bottom: 14px;
    color: var(--color-black);
    margin-inline: auto;
  }
}
