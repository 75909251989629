.collapsable-header {
  --height: 150px;

  position: sticky;
  top: 0;
  z-index: 2;
  display: grid;
  flex-shrink: 0;
  height: 150px;

  &._collapsed {
    --height: 70px;
  }

  &__trigger {
    position: absolute;
    top: 60px;
  }

  &__content {
    position: absolute;
    top: 0;
    width: 100%;
    height: var(--height);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--separator-color);
    transition: height .3s ease-out;
  }
}
