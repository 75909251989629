.modal {
  width: 50%;
  overflow: auto;

  &__header {
    border-bottom: solid 1px var(--color-black);
  }

  &__subtitle {
    font-size: 20px;
    font-weight: normal;
  }

  &__footer {
    padding: 20px 0 0 ;
    border-top: solid 1px var(--color-black);
  }

  &__button {
    width: 145px;
    height: 70px;
    margin-right: 0;
    margin-left: auto;
    font-size: 20px;
    font-weight: normal;
    color: var(--color-black);
    border: none;
    box-shadow: inset 0 0 0 1px var(--color-black);
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }

    &_save {
      width: 290px;
      margin-left: 10px;
      background-color: var(--color-yellow-60);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 0;
  font-size: 20px;

  &__top-section {
    position: sticky;
    top: 20px;
    z-index: 1;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 60px 20px 0;
    background-color: var(--color-white);
    border-bottom: solid 1px var(--color-white-5);

  }

  &__top-section-divider {
    width: 1px;
    height: 110px;
    margin-left: auto;
    background-color: var(--color-black-20);
  }

  &__title {
    flex-grow: 1;
    padding-bottom: 10px;
    font-size: 50px;
    line-height: 1;
  }

  &__main-content {
    overflow: auto;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: none;
    
    & td {
      height: 50px !important;
      padding-block: 0;
      background-color: var(--color-white);
      box-shadow: inset 0 -1px 0 var(--color-white-5);
    }

  }

  &__header-column {
    font-weight: normal;
  }

  &__row {
    box-shadow: inset 0 -1px 0 var(--color-white-5);

    &:first-child td {
      padding-top: 0 !important;
    }
  }

  &__column {
    --column-height: 67px;
    padding-block: 0;
    height: var(--column-height);
    vertical-align: middle;

    &_inline-gap {
      padding-inline: 10px;
    }

    &_clicked {
      position: relative;

      &::after {
        --right-offset: 154px;

        position: absolute;
        top: 7px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 50px;
        content: '';
        background-color: var(--color-yellow-60);
      }
    }

    &_icon {
      padding-inline: 30px 60px !important;
      display: flex;
      align-items: center;
    }
  }

  &__column-content {
    &_bold {
      font-weight: 500;
    }

    &_light {
      color: var(--color-gray-70);
    }
  }
}