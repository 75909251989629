.footer-block {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 110px;
  padding-inline: 20px;

  &::before {
    position: absolute;
    top: 0;
    right: 20px;
    left: 20px;
    content: '';
    border-bottom: 1px solid var(--color-black-20);
  }

  &__controls {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--button-color-disabled);
      pointer-events: all;
      cursor: not-allowed;
      box-shadow: 0 0 0 1px var(--button-bg-color-disabled);
    }

    &_close {
      display: flex;
      justify-content: space-around;
      width: 145px;
      font-weight: normal;
      box-shadow: inset 0 0 0 1px var(--color-black);

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }
    }

    &_save {
      width: 290px;
      font-weight: 600;
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 1px var(--color-yellow-60);
      transition: box-shadow .3s ease-out;

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }

      &:disabled {
        background-color: var(--color-white-15);
      }
    }
  }
}
