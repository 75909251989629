.column {
  position: relative;
  height: 55px;
  box-shadow: none;
  
  &_fixed {
    width: calc(150px / var(--zoom, 1));
  }

  &_section-start {
    &::before {
      position: absolute;
      top: 0;
      left: -20px;
      z-index: 2;
      width: 1px;
      height: calc(var(--divider-height) / var(--zoom, 1));
      content: '';
      background-color: var(--color-white-5);
    }

    &:first-child::before {
      display: none;
    }
  }

  &_section-end {
    padding-right: 40px;

    &::after {
      position: absolute;
      right: 0;
      bottom: -5px;
      z-index: 1;
      width: 40px;
      height: 10px;
      content: '';
      background-color: var(--color-white-15);
    }
  }

  &:last-child::after {
    display: none;
  }

  &__label {
    position: relative;
    align-self: center;
    font-size: 14px;
    line-height: 17px;
    color: var(--color-gray-70);
    text-transform: uppercase;
    letter-spacing: 1px;

    &_advanced {
      max-width: 150px;
      text-align: center;
    }

    &_wide {
      max-width: 220px;
    }

    &_link {
      text-decoration: underline;
    }

    &_no-transform {
      text-transform: none;
    }
  }

  &__waves-label {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 70px;
  }
}
