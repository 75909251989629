.design-modal {
  width: 950px;
  height: 530px;

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 150px;
    padding-bottom: 20px;
  }

  &__header {
    border-color: var(--color-black-20);
  }

  &__footer {
    border-color: var(--color-black-20);
  }
}

.run-data {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.action {
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  align-items: center;
  width: 100%;
  height: 70px;
  font-size: 20px;
  color: var(--color-black);
  border: none;
  border-radius: 10px;
  transition: box-shadow .3s ease-out, background-color .3s ease-out;

  &:focus-visible {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }

  &:hover:enabled,
  &:active:enabled {
    background-color: var(--color-yellow-60);
    box-shadow: inset 0 0 0 2px var(--color-black);
  }

  &_manifest {
    background-color: var(--color-white);
    box-shadow: inset 0 0 0 1px var(--color-black);
  }

  &_dashboard {
    font-weight: 600;
    background-color: var(--color-yellow-60);
    box-shadow: inset 0 0 0 1px var(--color-yellow-60);
  }

  &_disabled {
    pointer-events: none;
    opacity: .6;
  }
}

.info-row {
  display: grid;
  grid-template-columns: 150px 1fr;

  &__title {
    font-size: 20px;
    font-weight: normal;
  }

  &__text {
    font-size: 20px;
    font-weight: 600;
  }

  &__run-data {
    overflow: hidden;
  }
}

.run-info-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20px;
}

.lanes-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  border-left: 1px solid var(--color-black-20);

  &__lanes-count {
    font-size: 90px;
    line-height: 55px;
  }
}

.run-name {
  font-size: 33px;
  font-weight: 600;
  line-height: 38px;
}

.assays {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.assay-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  color: var(--color-black-20);
  background-color: var(--color-yellow-60);
  border-radius: 10px;

  svg {
    width: 27px;
    height: 27px;
  }
}
