@import '@/styles/variables';

.media-option {
  position: relative;
  padding-block: 10px !important;

  &:first-child::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--color-white-5);
  }

  &__content {
    font-size: var(--run-design-card-inputs-default-font-size);
    font-weight: normal;
    color: var(--color-black);
  }
}
