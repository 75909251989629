.schema-component {
  --incubation-bg-color: #CFF3FB;
  --scan-bg-color: #38DDFE;
  --step-scan-width: 90px;
  --step-height: 54px;
  --step-scan-color: var(--color-black);

  position: relative;
  grid-row-start: var(--row, 1);
  grid-column-start: var(--column, 1);
  grid-column-end: var(--column-end, span 1);
  height: var(--step-height);
  cursor: pointer;
  background-color: var(--incubation-bg-color);
  transition: background-color .3s ease-out;

  &_with-stain {
    position: absolute;
    bottom: calc(-53px * var(--scan-trail-multiplier));
  }

  &::before {
    position: absolute;
    top: 0;
    right: calc(var(--step-scan-width) - 2px);
    bottom: 0;
    left: 0;
    content: '';
    border: 2px solid var(--scan-bg-color);
    transition: scale .3s .2s ease-in, border-color .3s ease-out;
    transform-origin: right center;
    scale: 0 1;
  }

  &::after {
    position: absolute;
    top: 2px;
    right: calc(var(--step-scan-width) - 2px);
    bottom: 2px;
    left: 0;
    content: '';
    background-color: inherit;
    transition: scale .2s ease-in;
    scale: 1 1;
  }

  &:hover,
  &:active {
    &::before {
      scale: 1 1;
      transition: scale .3s ease-out;
    }

    &::after {
      scale: 1 0;
      transition: scale .2s .3s ease-out;
    }
  }

  &:focus-visible {
    outline: 2px solid var(--focus-color);
  }

  &_current {
    --incubation-bg-color: var(--color-yellow-60);
    --scan-bg-color: var(--color-black-20);
    --step-scan-color: var(--color-white);
  }

  &_no-scan {
    --step-scan-width: 2px;
  }

  &_cell-killing {
    display: grid;
    grid-template-columns: subgrid;
  }

  &__drop-wrap {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__drop-zone {
    &._is-over {
      --icon-opacity: 0.9;
    }
  }

  &__drop {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-white);
    border-radius: 10px 0 0 10px;
    translate: 0 -50%;
    opacity: var(--icon-opacity, 0);
    transition: opacity .3s ease-out;

    &_before {
      left: 0;
      scale: -1 1;
    }

    &_after {
      right: 0;
      left: auto;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr var(--step-scan-width);
    height: 100%;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: .5px;
  }

  &__step {
    min-width: 50px;
    height: 100%;
    padding-top: 11px;
    padding-left: 14px;
    text-align: left;
    container-type: size;
  }

  &__incubation {
    position: relative;
    z-index: 1;
  }

  &__scan {
    position: relative;
    z-index: 1;
    color: var(--step-scan-color);
    background-color: var(--scan-bg-color);
    transition: color .3s ease-out, background-color .3s ease-out;

    &_trail::before {
      position: absolute;
      top: calc(56px * var(--scan-trail-multiplier));
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: #38DDFE;
    }

    &_cell-killing-selected::before {
      background-color: var(--color-black-20);
    }
  }

  &_cell-killing &__scan {
    position: absolute;
    right: 0;
    width: var(--step-scan-width);
  }

  &__name {
    @container (max-width: 120px) {
      --abbreviation-display: inline;
      display: none;
    }

    &_abbreviation {
      display: var(--abbreviation-display, none);
    }
  }
}
