@import '@/styles/variables';

.minimized-cell-type {
  &__table {
    padding-inline: 20px;
  }

  &__header-title {
    @include run-design-table-header-labels;

    &_no-uppercase {
      text-transform: none;
    }
  }
}

.name-fcr {
  &__cell {
    display: flex;
    flex-direction: column;
  }
}

.cell {
  &__content {
    @include run-design-default-cell;
    display: grid;
    flex-direction: column;
    height: 55px;
    overflow: hidden;
    word-wrap: break-word;
    background-color: var(--color-white);
    border-radius: 10px;

    &_name {
      @include run-design-cell-name;
      padding: 7px;
      white-space: nowrap;
    }
  }

  &__text {
    display: -webkit-box;
    align-content: center;
    overflow: hidden;
    font-size: var(--run-design-card-inputs-default-font-size);
    line-height: 1.15;
    text-overflow: ellipsis;
    text-wrap: balance;
    white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__ptrt {
    align-items: center;
  }
}
