@import '@/styles/variables';

.skeleton {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.no-data {
  @include no-data-block;
}


.timeline {
  position: relative;
  overflow: hidden;

  &__header {
    display: flex;
    gap: 35px;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 10px;
    line-height: 20px;
    border-bottom: solid 1px var(--light-color-gray-10);
  }

  &__header-titles {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__header-title {
    font-size: 20px;
  }

  &__content {
    margin-block: 15px;
    position: relative;
    height: calc(100% - 70px);
    overflow: hidden;
  }

  &__chart {
    height: 100%;
  }
}


.download-btn {
  display: grid;
  place-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: var(--color-white-20);
  border-radius: 10px;

  & svg {
    width: 15px;
  }
}
