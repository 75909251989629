@keyframes loader-rotation {
  to {
    rotate: 360deg;
  }
}

.loader {
  --loader-radius-outer: var(--theme-loader-radius-outer, 35px);
  --loader-radius-inner: var(--theme-loader-radius-inner, 16px);
  --loader-color-below: var(--theme-loader-color-below, var(--color-yellow-40));
  --loader-color-under: var(--theme-loader-color-under, var(--color-gray-55));

  position: relative;
  width: calc(var(--loader-radius-outer) * 2);
  height: calc(var(--loader-radius-outer) * 2);
  background: conic-gradient(var(--loader-color-below), var(--loader-color-under) 330deg);
  border-radius: 50%;
  mask-image:
    radial-gradient(
      circle,
      transparent var(--loader-radius-inner),
      black calc(var(--loader-radius-inner) + 1px)
    );
  animation: loader-rotation 4s linear infinite;

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(var(--loader-radius-outer) - var(--loader-radius-inner));
    height: calc(var(--loader-radius-outer) - var(--loader-radius-inner));
    content: '';
    background-color: var(--loader-color-under);
    border-radius: 50%;
    transform: translateX(-50%);
  }
}
