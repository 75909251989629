@import '@/styles/variables';

.label {
  display: flex;
  gap: 15px;
  font-size: 20px;
  cursor: pointer;
}

.radio {
  position: relative;
  width: 28px;
  height: 28px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    content: '';
    background-color: var(--bg-color, var(--light-color-gray-20));
    border: 9px solid var(--border-color, var(--light-color-gray-20));
    border-radius: 50%;
    transition: border .3s ease-out, background-color .3s ease-out;
  }
}

.radio:has(.input:checked) {
  --bg-color: var(--color-white);
  --border-color: var(--color-black);
}
