.cell-types {
  display: flex;
  gap: 5px;
  align-items: center;
  max-width: 110px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cell-type {
  width: 15px;
  min-width: 15px;
  height: 15px;
  background-color: var(--light-color-gray-20);
  border-radius: 50%;

  &_active {
    background-color: var(--color-black);
  }
}
