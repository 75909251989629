@import '@/styles/variables';

.matrix-view {
  &__table-content {
    width: 100%;
    overflow: hidden;
  }

  &__table-wrapper {
    --container-bottom-gap: 25px;

    width: 100%;
    height: calc(100% - var(--container-bottom-gap));
    padding-bottom: 40px;
    overflow: auto;
  }

  &__sub-header {
    padding-block: 20px;
    font-size: 20px;
    font-weight: normal;
    border-bottom: 1px solid var(--color-white-5);
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    & thead {
      font-size: 14px;
      border-bottom: none;
    }

    & thead td {
      min-width: 94px;
      padding-block: 0;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 55px;
    vertical-align: baseline;
    background-color: var(--color-white);
    box-shadow: inset 0 -1px 0 var(--color-white-5);

    td {
      padding-top: 15px;
      vertical-align: middle;
    }
  }

  &__header-col {
    &_reagent{
      padding-left: 10px;
    }

    &_assay {
      width: 100%;
      padding-left: 5px;
    }
  }

  &__header-with-info {
    display: flex;
    gap: 7px;
    align-items: center;
  }

  &__content {
    display: flex;
    gap: 30px;
    width: 100%;
  }

  &__lanes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 40px 0 20px;
    margin-block: 20px;
    border-right: 1px solid var(--color-white-5);
  }

  &__input {
    --input-border-color: var(--color-white-5);
    --input-border-color-disabled: var(--color-gray-80);
    --input-font-size: 20px;
    --input-font-weight: normal;
    --input-height: 55px;
    --input-border-color-focus: var(--color-black);

    height: max-content;

    &_changed {
      --input-bg-color: var(--color-yellow-60);
    }

    input {
      width: 150px;
      text-align: center;
    }
  }

  &__body {
    & tr:first-child td{
      padding-top: 26px;
    }
  }

  &__row {
    vertical-align: middle;

    &_start-section td {
      position: relative;
      padding-top: 40px;

      &::before {
        position: absolute;
        top: 20px;
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--color-white-5);
      }
    }

    &_end-section td {
      padding-bottom: 0;
    }

    &:first-child td {
      padding-top: 10px;

      &::before {
        display: none;
      }
    }
  }

  &__col {
    padding: 0;
  }

  &__wave-channel {
    --wave-channel-width: 165px;
    --wave-channel-height: 55px;
    --wave-channel-font-size: 20px;
    --wave-channel-border-radius: 10px;
  }
}

.content-wrapper,
.assay-wrapper {
  @include run-design-default-cell;

  height: 100%;
  padding: 5px;
  background-color: var(--color-white-15);

}

.content-wrapper {
  padding-left: 10px;

  &_top-rounded {
    padding-top: 10px;
    border-top-left-radius: 10px;
  }

  &_bottom-rounded {
    padding-bottom: 10px;
    border-bottom-left-radius: 10px;
  }
}

.assay-wrapper {
  padding-right: 10px;

  &_top-rounded {
    padding-top: 10px;
    border-top-right-radius: 10px;
  }

  &_bottom-rounded {
    padding-bottom: 10px;
    border-bottom-right-radius: 10px;
  }
}

.lane,
.scan {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-size: 20px;
  color: var(--color-black);
  border: 1px solid var(--color-white-5);
  border-radius: 10px;
}

.lane {
  color: var(--color-black);
  background-color: var(--color-white);
  border-color: var(--color-black);
  border-radius: 50%;
  transition: background-color .3s ease-out;

  &_selected {
    color: var(--color-white);
    background-color: var(--color-black);

    &:hover:enabled {
      color: var(--color-black);
      background-color: var(--color-white-15);
    }
  }

  &_non-existent {
    cursor: default;
    border-color: var(--color-white-5);
  }

  &:hover:enabled {
    background-color: var(--color-white-15);
    border-color: var(--color-white-15);
  }
}

.assay {
  display: grid;
  grid-template-columns: 55px 1fr;
  height: 55px;
  background-color: var(--color-white);
  border: 1px solid var(--color-white-5);
  border-radius: 10px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-inline: 10px;
    border-left: 1px solid var(--color-white-5);
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-data {
  --header-height: 120px;
  position: relative;
  padding-top: 50px;

  &::before {
    position: absolute;
    top: var(--header-height);
    z-index: 3;
    width: 100%;
    height: 10px;
    content: '';
    background-color: var(--color-white);
  }
}
