.custom-information {
  &__group-row {
    padding-block: 20px;
    border-bottom: 1px solid var(--light-color-gray-5);

    &:last-child {
      border-bottom: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__text {
    display: flex;
    align-items: flex-start;
    padding-top: 13px;
    font-size: 20px;
    font-weight: normal;
  }

  &__text-required {
    color: var(--required-text-color, inherit);
    transition: color .3s ease-out;
  }

  &__input {
    --input-padding: 0 15px;

    height: 55px;
    font-size: 20px;
    font-weight: normal;
    background: var(--color-white-15);
    border: none;
    border-radius: 10px;
    transition: background-color .3s ease-out;
    appearance: none;


    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &::placeholder {
      color: var(--placeholder-color, var(--color-gray-70));
      opacity: 1;
    }

    &:user-invalid {
      --placeholder-color: var(--color-red-20);
      background: var(--color-red-50);
    }
  }

  &__text:has(+ &__input-wrap > input:user-invalid) {
    --required-text-color: var(--color-red-20);
  }

  &__info {
    display: flex;
    align-items: flex-start;
    min-height: 55px;
    padding-top: 13px;
    padding-left: 15px;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: .5px;
    word-break: break-word;
  }

  &__link {
    transition: color .1s ease-out;

    &:hover {
      color: var(--color-purple);
    }
  }

  &__link-icon {
    margin-left: 3px;
  }

  &__textarea {
    height: 150px;
    padding: 15px;
    font-size: 20px;
    font-weight: normal;
    background: var(--color-white-15);
    border: none;
    border-radius: 10px;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &::placeholder {
      color: var(--color-gray-70);
      opacity: 1;
    }
  }

  &__radio-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 55px;
  }

  &__radio {
    --radio-background-color: var(--light-color-gray-20);
    --radio-dot-color: transparent;

    gap: 22px;
    font-size: 20px;

    &:has(> :focus-visible)::before {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &::before {
      background-color: var(--radio-background-color) !important;
    }

    &::after {
      background-color: var(--radio-dot-color) !important;
    }

    &:has(> :checked) {
      --radio-background-color: var(--color-yellow-60);
      --radio-dot-color: var(--color-black)
    }
  }
}
