.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-black);
  padding-inline: 20px;
}

.actions {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: -20px;
}

.action-btn {
  --button-background-color: var(--color-black) !important;
  --button-border-color: var(--color-black) !important;

  height: 100%;
  border: none;
  border-radius: 0;

  & + & {
    &::before {
      position: absolute;
      left: -1px;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-gray-60);
    }
  }
}
