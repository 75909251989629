@import '@/styles/variables';

.global-view {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr .6fr;
    align-items: start;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  &__table-wrapper {
    width: max-content;
    height: 100%;
    padding: 0 20px 15px 0;
  }

  &__header {
    @include run-design-table-header-labels;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 75px;
    vertical-align: baseline;
    background-color: var(--color-white);

    td {
      padding-block: 15px 13px;
    }
  }

  &__header-range {
    padding-top: 5px;
    font-size: 19px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0.9px;
  }

  &__input {
    --input-border-color: var(--color-white-5);
    --input-border-color-disabled: var(--color-gray-80);
    --input-font-size: 20px;
    --input-font-weight: normal;
    --input-height: 55px;
    --input-border-color-focus: var(--color-black);

    &_changed {
      --input-bg-color: var(--color-yellow-60);
    }

    input {
      text-align: center;
    }
  }

  &__body {
    padding-bottom: 20px;

    & td {
      padding-block: 10px;
    }

    & tr:first-child td{
      padding-top: 26px
    }
  }

  &__column {
    width: 165px;
    font-size: 20px;
  }

  &__header-title{
    position: relative;
    margin-bottom: 15px;
    font-weight: 500;

    &::after {
      position: absolute;
      bottom: -14px;
      left: 0;
      width: 150px;
      height: 1px;
      content: '';
      background-color: var(--color-white-5);
    }
  }

  &__wave-channel {
    --wave-channel-width: 165px;
    --wave-channel-height: 55px;
    --wave-channel-font-size: 20px;
    --wave-channel-border-radius: 10px;
  }
}

.lane,
.number-field {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  font-size: 20px;
  color: var(--color-black);
  border: 1px solid var(--color-white-5);
  border-radius: 10px;
}

.number-field {
  width: 100%;

  &_changed {
    background-color: var(--color-yellow-60);
  }
}
