.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 28px;
  line-height: 1.1;

  &__title-wrapper {
    align-self: center;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    gap: 5px;
  }

  &__switch {
    --switch-height: 55px;
    --line-height: 55px;
    --font-size: 20px;
    --background-color: var(--color-white-20);
    padding-top: 20px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  height: 55px;
  font-size: 20px;
  font-weight: normal;
  color: var(--color-black);
  white-space: nowrap;
  border-radius: 100px;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;
  padding-inline: 20px;

  &:hover {
    --button-hover-background-color: var(--color-black) !important;
  }

  &_active {
    color: var(--color-white);
    background-color: var(--color-black);
  }
}
