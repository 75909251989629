.add-button {
  --button-color: var(--light-color-gray-15) !important;
  --button-background-color: transparent !important;
  --button-border-color: var(--light-color-gray-15) !important;

  --button-hover-color: var(--color-black) !important;
  --button-hover-background-color: transparent !important;
  --button-hover-border-color: var(--color-black) !important;

  --button-font-size: var(--run-design-card-inputs-default-font-size);
  position: relative;
  display: flex;
  gap: 25px;
  justify-content: flex-start;
  height: 55px;
  padding: 0;
  font-weight: normal;
  border-style: dashed;

  &__plus-icon {
    width: 20px;
    height: 20px;
  }
}
