.header {
  --image-main-color: #050a76;

  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-start: 1;
  grid-column-end: -1;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--image-main-color);
  border-radius: 20px;

  &__bg-image {
    position: absolute;
    inset: 0;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__front-image {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__column {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 20px;
    padding-bottom: 16px;

    &_wide {
      grid-column-end: span 2;
    }

    &:last-child::before {
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      width: 0;
      content: '';
      border-left: 1px solid var(--color-white);
    }
  }

  &__title {
    padding-top: 8px;
    font-size: 45px;
    font-weight: bold;
    line-height: 55px;
    color: var(--color-white);
    letter-spacing: -1px;
  }

  &__text {
    font-size: 20px;
    font-weight: normal;
    line-height: 28px;
    color: var(--color-white);
    letter-spacing: .25px;
  }

  &__question {
    padding-top: 14px;
  }

  &__learn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 55px;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--color-white);
    cursor: pointer;
    border: 1px solid var(--color-white);
    border-color: var(--color-white);
    border-radius: 100px;
    transition: background-color .3s ease-out;

    &:hover {
      background-color: rgba(255,255,255, .2);
    }
  }
}
