.list {
  --card-width: var(--cell-list-card-width, 590px);
  --left: 0;

  position: relative;
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(auto-fill, minmax(max-content, 1fr)); /* ! size of showing cards in viewport */
  grid-auto-columns: minmax(max-content, 1fr); /* ! size of hidden cards in viewport */
  grid-auto-flow: column; /* ! horizontal scroll */
  gap: 10px;
  overflow: overlay;
  container-type: inline-size; /* ! card size */

  &:has(.item_open) {
    overflow: hidden; /* ! hide scroll when some card is active */
  }
}

.item {
  position: relative;
  min-width: var(--card-width);

  &_open .item__content {
    left: calc(var(--left) * 1px);
    z-index: 1; /* ! that other card was under opened card */
    width: 100cqw; /* ! size opened card */
    transition: width 0.3s ease-out, left 0.3s ease-out; /* ! without z index for instantly setup */
  }
}

.item__content {
  position: relative;
  left: 0; /* ! for collapse animation */
  z-index: 0; /* !  for collapse animation */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 0 5px var(--color-white); /* ! hide on left and right corners */
  transition: width 0.3s ease-out, left 0.3s ease-out, z-index 0s 0.3s linear; /* ! animate z-index */
  will-change: width, left;
}
