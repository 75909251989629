.static-channel {
  display: flex;
  gap: 14px;
  align-items: center;
  height: 55px;
  padding-left: 7px;

  &__text{
    font-size: 20px;
  }
}
