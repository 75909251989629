.popover {
  &__content {
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 10px 20px;
    font-size: 19px;
    color: var(--color-white);
    background-color: var(--color-black);
    border-radius: 6px;
    box-shadow: 0 0 20px 0 var(--color-black);
  }

  &__title {
    margin-bottom: 10px;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    padding-block: 5px;
  }

  &__list-item-title {
    color: var(--light-color-gray-15)
  }
}

.info-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: solid 2px var(--color-black);
  border-radius: 20px;
  transition: background-color .3s ease-out;

  &:hover &__icon {
    color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-black);
  }

  &__icon {
    transition: color .3s ease-out;
  }
}