.radio {
  --radio-padding-left: 30px;
  --radio-width: max-content;

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    width: var(--radio-width);
    height: 20px;
    padding-left: var(--radio-padding-left);
    margin-right: 10px;
    cursor: pointer;
    border-radius: 10px;
  }

  &__input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &_dark::before,
  &_light::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    border-radius: 10px;
    transition: background-color .3s ease-in;
  }

  &_dark::after,
  &_light::after {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 10px;
    opacity: 0;
  }

  &_dark {
    &::before {
      border: solid 2px var(--color-white);
    }
  }

  &_light {
    &::before {
      border: solid 2px var(--icons-border-color);
    }
  }

  &_light:has(> :checked) {
    &::before {
      background-color: var(--color-black-20);
      border: none;
    }

    &::after {
      background-color: var(--color-white);
      opacity: 1;
    }
  }

  &_dark:has(> :checked) {
    &::before {
      background-color: var(--color-white);
      border: none;
    }

    &::after {
      background-color: var(--color-black-20);
      opacity: 1;
    }
  }
}
