.context-menu {
  display: flex;
  flex-direction: column;
  width: 150px;
  max-height: 500px;
  overflow-y: auto;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 1px 2px var(--color-white-10);
  padding-block: 5px;
}

.action {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 40px;
  transition: flex-grow 0.3s ease-out, background-color 0.3s ease-out;

  &:hover {
    flex-grow: 2;
    background-color: var(--color-white-10);
  }
}
