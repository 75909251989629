.title-block {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 155px;
    padding: 10px 20px 20px;

    &::before {
      position: absolute;
      right: 20px;
      bottom: 0;
      left: 20px;
      content: '';
      border-bottom: 1px solid var(--separator-light-color);
    }

  &__wrap {
    overflow: hidden;
  }

  &__title {
    width: 100%;
    overflow: hidden;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-overflow: ellipsis;
    letter-spacing: -0.15px;
    white-space: nowrap;
    translate: 0 -4px;
  }

  &__slash {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-left: 5px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: calc(100% * 1.43);
      content: '';
      border-right: 4px solid currentcolor;
      translate: -50% -50%;
      rotate: 45deg;
    }
  }

  &__description {
    font-size: 20px;
    font-weight: normal;
  }

  &__icon-wrap {
    position: relative;
    height: 110px;
    padding-left: 20px;
    margin-top: 10px;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      border-left: 1px solid var(--color-black-20);
    }
  }
}
