@import '@/styles/variables';

.header {
  padding-bottom: 18px;

  &__title-wrapper {
    flex-grow: 1;
    padding-bottom: 18px;
  }

  &__title {
    height: max-content;
    padding-bottom: 5px;
  }
  
  &__subtitle {
    font-weight: 600;
    line-height: normal;
  }

  &__buttons {
    display: flex;
    gap: 30px;
    align-items: flex-end;
    margin-top: 5px;
    font-size: 20px;
  }

  &__button {
    width: max-content;
    height: 30px;

    &_reset {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      width: 200px;
      height: 30px;
      margin-left: 0;
      font-weight: normal;
      border: none;
      box-shadow: none;

      &::after {
        position: absolute;
        top: -1px;
        left: -15px;
        width: 1px;
        height: 100%;
        cursor: auto;
        content: '';
        background-color: var(--color-black);
      }
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
  }

  &__button-substring {
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      content: "";
      border-bottom: 1px dashed var(--color-black);
    }
  }

  &__button-icon {
    color: var(--color-black);
    transform: scale(-1, 1) rotate(-90deg);
  }
}
