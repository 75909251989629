@import '@/styles/variables';

.global-settings {
  width: 100%;

  &__header {
    padding-bottom: 15px;
    font-size: 20px;
    border-bottom: solid 1px var(--color-black);
  }

  &__header-title {
    font-weight: 600;
  }

  &__header-buttons {
    display: flex;
    gap: 30px;
    padding-top: 10px;
  }

  &__input {
    --background-color: var(--color-white-15);
    max-width: 145px;
    height: 50px;
  }

  &__input-value {
    text-align: left;
  }

  &__input-container {
    max-width: 300px;
  }

  &__button {
    width: max-content;

    &_reset {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      width: 200px;
      height: 30px;
      margin-left: 0;
      border: none;
      box-shadow: none;

      &::after {
        position: absolute;
        top: 0;
        left: -15px;
        width: 1px;
        height: 100%;
        cursor: auto;
        content: '';
        background-color: var(--color-black);
      }
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
  }

  &__button-substring {
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 0;
      content: "";
      border-bottom: 1px dashed var(--color-black);
    }
  }

  &__button-icon {
    color: var(--color-black);
    transform: scale(-1, 1) rotate(-90deg);
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  width: 100%;
  font-size: 16px;

  &_clickable {
    cursor: pointer;
  }
}

.info {
  padding-top: 20px;
  font-size: 16px;

  &__text {
    color: var(--color-gray-70);
    padding-inline: 5px;
  }
}

.custom-popover {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5px;
  
  &__values {
    display: flex;
    flex-direction: column;
    line-height:    22.67px;
  }

  &__key {
    color: var(--color-gray-70);
  }
}
