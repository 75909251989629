.info-button {
  --tooltip-colour: #b1b1b1;
  --tooltip-hover-colour: #888;
  --info-button-size: 26px;

  width: var(--info-button-size);
  height: var(--info-button-size);
  padding: 0;
  font-family: SFMono, Arial, Helvetica, sans-serif;
  font-size: calc(var(--info-button-size) / 2);
  font-weight: bold;
  color: var(--tooltip-colour);
  border: solid 2px var(--tooltip-colour);
  border-radius: 50%;

  &:hover:enabled {
    color: var(--color-white-10);
    background-color: var(--tooltip-hover-colour);
    border-color: var(--tooltip-hover-colour);
  }
}
