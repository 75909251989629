@import '@/styles/variables';

.content {
  @include run-design-card-content;
  width: 100%;
  background-color: var(--color-white);

  &::after {
    top: 93px;
    left: 0;
    z-index: 2;
    width: 100%;
  }

  &_matrix{
    --card-left-content-width: 145px;
    --card-right-gap: 10px;

    &::after{
      visibility: hidden;
    }
  }
}
