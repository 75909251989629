.delete-button {
  display: flex;
  gap: 14px;
  width: max-content;
  font-weight: normal;
  box-shadow: inset 0 0 0 1px var(--color-black);

  &__icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
}
