.timeline-card {
  --default-bg-color: #53E2FF;
  --scan-bg-color: var(--color-gray-10);
  --default-color: var(--color-black);
  --scan-color: var(--color-white);
  --default-bg-color-hover: var(--color-yellow-60);
  --scan-bg-color-hover: #404040;
  --scan-width: clamp(80px, 30%, 150px);

  position: relative;
  grid-row-start: var(--row, 1);
  grid-column-start: var(--column, 1);
  grid-column-end: var(--column-end, span 1);
  min-height: 180px;
  text-align: left;

  &:hover {
    --default-bg-color: var(--default-bg-color-hover);
    --scan-bg-color: var(--scan-bg-color-hover);
  }

  &_simultaneous {
    --scan-width: 0;
    --scan-display: none;
  }

  &_no-scan{
    --scan-width: 1px;
    --scan-display: none;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr var(--scan-width);
    height: 100%;
    cursor: pointer;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px 10px;
    transition: background-color .3s ease-out;

    &_default {
      color: var(--default-color);
      background-color: var(--default-bg-color);
    }

    &_scan {
      position: relative;
      display: var(--scan-display, flex);
      color: var(--scan-color);
      background-color: var(--scan-bg-color);

      &::before {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 1;
        height: calc(300% - var(--row, 1) * 100%);
        pointer-events: none;
        content: '';
        background-image: repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%);
        background-size: 6px 6px;
        mix-blend-mode: multiply;
      }
    }
  }

  &__title {
    display: block;
    color: inherit;
    container-type: inline-size;
  }

  &__text {
    @container (max-width: 90px) {
      --abbreviation-display: inline;
      display: none;
    }

    &_abbreviation {
      display: var(--abbreviation-display, none);
    }
  }

  &__stain {
    position: absolute;
    bottom: calc(-190px * var(--component-stain-row));
    left: 10px;
    z-index: 1;
    content: '';

    &_left-gap {
      left: 65px;
    }
  }

}
