.table {
  width: 100%;
  padding-inline: 20px;

  &__row:has(&__cell:nth-child(2)) {
    --cell-count: 2;
  }

  &__row:has(&__cell:nth-child(3)) {
    --cell-count: 3;
  }

  &__cell {
    width: calc(100% / var(--cell-count, 1));
    max-width: 10px; // for even cell width distribution
    height: 30px;
  }

  &__header {
    height: 50px;
    overflow: hidden;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: .5px;
    white-space: nowrap;
    border-bottom: 1px solid var(--color-gray-80);
  }
}
