.list-block {
  height: 100%;
  padding-bottom: 5px;
  overflow-y: scroll;
  
  &__list {
    column-gap: 30px;
    columns: 2 auto;
  }
}
