.time-input {
  width: 100px;
  height: 55px;

  &::before {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 35px;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: normal;
    color: var(--color-gray-70);
    content: var(--letter, '');
    border-left: 1px solid var(--color-gray-80);
  }

  &._day {
    --letter: 'D';
  }

  &._hour {
    --letter: 'H';
  }

  &._minute {
    --letter: 'M';
  }

  &._second {
    --letter: 'S';
  }
}
