.title-block {
  --icon-column-width: 250px;

  position: relative;
  display: grid;
  grid-template-columns: var(--icon-column-width) 1fr 1fr var(--icon-column-width);
  height: 150px;
  padding-inline: 20px;

  &::before {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    content: "";
    border-bottom: 1px solid var(--color-gray-80);
  }

  &__column {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;

    &:first-child {
      grid-column-end: span 2;
      padding-left: 0;

      &::before {
        opacity: 0;
      }
    }

    &::before {
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      content: '';
      border-left: 1px solid var(--color-gray-80);
    }
  }

  &__title {
    font-size: 50px;
    font-weight: bold;
    line-height: 55px;
    letter-spacing: -1px;
  }

  &__pause-wrap {
    display: flex;
    gap: 10px;
    font-size: 20px;
    font-weight: normal;
  }

  &__step-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: -.5px;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > svg {
      width: 70px;
      height: 70px;
    }
  }

  &__timing-placement {
    display: flex;
    gap: 12px;
  }

  &__select {
    --select-bg-color: var(--color-white-15);
    --select-border-color: var(--color-white-15);
    --select-border-width: 1px;
    --select-font-size: 20px;

    flex-basis: 50%;
    flex-grow: 1;
    max-width: 280px;
    color: var(--color-black);
  }

  &__select-control {
    height: 55px;
    padding: 0 5px;
  }

  &__select-menu {
    --select-menu-margin-block: -55px;
  }

  &__remove-wrap {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__remove {
    --border-color: transparent;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: var(--color-black);
    text-transform: uppercase;
    letter-spacing: .5px;
    transition: background-color .3s ease-out, opacity .3s ease-out;

    &_hidden {
      pointer-events: none;
      opacity: 0;
    }

    &:hover,
    &:active {
      --border-color: var(--color-black);
      --bg-color: var(--color-yellow-60);
    }

    &:focus-visible {
      outline: none;
    }

    &::before {
      position: absolute;
      inset: 0;
      width: 55px;
      height: 55px;
      content: '';
      background-color: var(--bg-color, var(--color-white));
      border: 1px solid var(--border-color);
      border-radius: 50%;
      transition: border-color .3s ease-out, background-color .3s ease-out;
    }

    &:focus-visible::before {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }

  &__remove-icon {
    z-index: 1;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
  }

  &__dialog {
    width: clamp(420px, 60vw, 840px);
    min-height: 320px;
    aspect-ratio: 2;
  }

  &__confirmation {
    display: grid;
    grid-template-rows: 1fr 110px;
    padding: 20px 20px 0;
  }

  &__confirmation-controls {
    display: flex;
    gap: 12px;
    align-items: center;
    border-top: 1px solid var(--color-black-20);
  }

  &__confirmation-button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--button-color-disabled);
      pointer-events: all;
      cursor: not-allowed;
      box-shadow: 0 0 0 1px var(--button-bg-color-disabled);
    }

    &_cancel {
      display: flex;
      justify-content: center;
      font-weight: normal;
      box-shadow: inset 0 0 0 1px var(--color-black);

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }
    }

    &_confirm {
      font-weight: 600;
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 1px var(--color-yellow-60);
      transition: box-shadow .3s ease-out;

      &:hover:enabled,
      &:active:enabled {
        background-color: var(--color-yellow-60);
        box-shadow: inset 0 0 0 2px var(--color-black);
      }
    }
  }

  &__confirmation-title {
    margin-bottom: 14px;
    font-size: 33px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.5px;
  }

  &__confirmation-text {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.25px;
  }

  &__tag {
    --tag-color: #CFF3FB;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-family: SFMono, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    background-color: var(--tag-color);
    border-radius: 15px;
    padding-inline: 11px;
    translate: 0 -3px;

    &_current {
      background-color: var(--color-yellow-60);
    }
  }

  &__cell-killing-placement {
    padding: 15px;
    font-size: 20px;
    border: dashed 1px var(--color-gray-70);
    border-radius: 10px;
  }

  &__checkbox-label {
    font-size: 20px;
    color: var(--color-black);
  }
}
