.column {
  width: max-content;
  height: 55px;
  padding: 5px 10px 5px 0;

  &:empty {
    --inline-gap: 10px;
    height: calc(55px + var(--inline-gap));
  }
}
