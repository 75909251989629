.re-selectable {
  position: relative;
  height: 100%;
  padding-right: var(--trigger-width, 0);

  &:global(._select-open) {
    --content-bg-color: var(--color-white);
    --select-trigger-bg-color: #D4D4D4;
  }

  &._with-selection {
    --trigger-width: 37px;
    --is-with-selection: 1;
  }

  &__wrap {
    position: relative;
    top: 1px;
    height: calc(100% - 2px);
    background-color: var(--content-bg-color, inherit);
    border-radius: 8px calc((1 - var(--is-with-selection, 0)) * 8px) calc((1 - var(--is-with-selection, 0)) * 8px) 8px;

    &:hover {
      background-color: var(--color-white);
    }
  }

  &__select-trigger {
    position: absolute;
    top: 1px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: calc(100% - 2px);
    background-color: var(--select-trigger-bg-color);
    border-left: 1px dashed var(--select-border-color);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: .3s background-color ease-out;

    &:hover,
    &:active {
      --select-trigger-bg-color: var(--color-white);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }
  }
}
