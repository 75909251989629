.wave-channel-group {
  display: flex;

  > .wave-channel-item {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.wave-channel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--wave-channel-width, 40px);
  height: var(--wave-channel-height, 40px);
  font-size: 16px;

  /* This swaps between white or black based on the background color. */
  color: lch(from var(--wave-channel-color) calc((49.44 - l) * infinity) 0 0);
  letter-spacing: 0.25px;
  background-color: var(--wave-channel-color);
  border-radius: var(--wave-channel-border-radius, 8px)
}
