.search-results {
  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    font-weight: normal;
    color: var(--color-gray-80);
  }

  &__table {
    display: grid;
    grid-template-columns:  var(--select-column-width, 0) var(--css-grid-layout) 50px;
  }

  &__table:has(&__table-cell_select) {
    --select-column-width: 68px;
  }

  &__table-row {
    display: contents;
    font-size: 20px;
    cursor: pointer;
  }

  &__table-row:has(&__radio-input:checked) {
    --is-row-selected: 1;
  }

  &__table-heading {
    --align-items: flex-end;
    --padding-bottom: 12px;
    --first-cell-padding-left: -11px;
    --min-cell-height: 50px;

    position: sticky;
    top: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: var(--select-column-width, 0) var(--css-grid-layout) 50px;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-gray-70);
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: default;
    background-color: var(--color-white);
  }

  &__table-heading:has(&__table-cell_select) {
    --select-column-width: 68px;
  }

  &__table-cell {
    position: relative;
    display: flex;
    align-items: var(--align-items, center);
    min-height: var(--min-cell-height, 68px);
    padding-bottom: var(--padding-bottom, 0);
    padding-left: 10px;
    border-bottom: 1px solid var(--light-color-gray-5);

    &_select {
      justify-content: center;
      padding-left: 0;
      margin-left: var(--first-cell-padding-left, -5px);
      border-right: 1px solid var(--light-color-gray-5);
    }

    &:last-child {
      padding-right: 10px;
    }

    &::before {
      position: absolute;
      top: 3px;
      right: 0;
      bottom: 3px;
      left: 0;
      content: '';
      background-color: var(--color-yellow-60);
      opacity: var(--is-row-selected, 0);
      transition: opacity .3s ease-out;
    }

    & > * {
      position: relative;
    }

    &_waves-heading {
      display: grid;
      grid-template-columns: repeat(2, 40px);
    }

    &:empty::after {
      content: '—';
    }
  }

  &__text {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__radio {
    --radio-padding-left: 3px;
    position: relative;
    width: 28px;
    height: 28px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 28px;
      height: 28px;
      content: '';
      background-color: var(--bg-color, var(--light-color-gray-20));
      border: 9px solid var(--border-color, var(--light-color-gray-20));
      border-radius: 50%;
      transition: border .3s ease-out, background-color .3s ease-out;
    }
  }

  &__radio-input {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  &__radio:has(&__radio-input:checked) {
    --bg-color: var(--color-white);
    --border-color: var(--color-black);
  }

  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-black);
    border-radius: 50%;
    translate: -8px -50%;
  }
}
