.aside-menu {
  position: relative;
  z-index: var(--z-aside-menu);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: var(--page-sidebar-width);
  height: 100vh;
  padding: 20px;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-white-20);

  &__logo-link {
    display: block;
    line-height: 1;
    color: var(--color-gray-20);
    transition: color .3s ease-out;

    &:hover {
      color: var(--color-purple);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
    }
  }

  &__logo {
    --logo-size: 40px;

    width: var(--logo-size);
    height: var(--logo-size);
    vertical-align: middle;
  }

  &__button {
    display: grid;
    place-content: center;
    width: 45px;
    height: 45px;
    padding: 0;
    color: var(--color-black);
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 10px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #f2f2f2;
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
    }
  }
}

.aside-nav {
  &__item {
    display: grid;
    place-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: var(--color-black);
    cursor: pointer;
    background-color: #f2f2f2;
    border: none;
    border-radius: 50%;
    transition: .3s color, .3s background-color;

    & + & {
      margin-top: 18px;
    }

    &_active,
    &:hover {
      color: var(--color-white);
      background-color:  var(--color-black);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
    }
  }
}
