.page-controls {
  --button-color-disabled: #808080;
  --button-bg-color-disabled: var(--color-white-15);

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__group {
    display: flex;
    gap: 10px;
  }

  &__button {
    height: 70px;
    font-size: 20px;
    color: var(--color-black);
    border: none;
    transition: box-shadow .3s ease-out, background-color .3s ease-out;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      color: var(--button-color-disabled);
      pointer-events: all;
      cursor: not-allowed;
      box-shadow: 0 0 0 1px var(--button-bg-color-disabled);
    }
  }

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: inset 0 0 0 1px var(--color-black);

    &:hover:enabled,
    &:active:enabled  {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }
  
  &__save {
    --save-border-color: var(--color-gray-80);
    width: 205px;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--save-border-color);

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }

  &__cancel,
  &__edit {
    display: flex;
    justify-content: space-around;
    width: 140px;
    font-weight: normal;
    box-shadow: inset 0 0 0 1px var(--color-black);

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }
  }

  &__next {
    width: 285px;
    font-weight: 600;
    background-color: var(--color-yellow-60);
    box-shadow: inset 0 0 0 1px var(--color-yellow-60);
    transition: box-shadow .3s ease-out;

    &:hover:enabled,
    &:active:enabled {
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }

    &:disabled {
      background-color: var(--color-white-15);
    }
  }

  &__step-selector {
    position: relative;
    display: grid;
    grid-template-columns: var(--text-width, 0fr);
    gap: 16px;
    align-items: center;
    min-width: 70px;
    height: 70px;
    border-radius: 50px;
    box-shadow: inset 0 0 0 1px var(--color-gray-80);
    transition: grid-template-columns .3s ease-out, padding .3s ease-out, box-shadow .3s ease-out, background-color
  .3s ease-out;
    padding-inline: 20px;

    &_current {
      --number-bg-color: var(--color-black);
      --number-color: var(--color-white);
      --text-width: 1fr;
      padding-right: 20px;
      padding-left: 70px;
    }

    &:hover:enabled,
    &:active:enabled {
      --number-bg-color: var(--color-yellow-60);
      background-color: var(--color-yellow-60);
      box-shadow: inset 0 0 0 2px var(--color-black);
    }

    &_current:hover:enabled,
    &_current:active:enabled {
      --number-bg-color: var(--color-black);
      --number-color: var(--color-yellow-60);
    }

    &:focus-visible {
      outline: 2px solid var(--focus-color);
      outline-offset: 2px;
    }

    &:disabled {
      background-color: var(--color-white-15);
    }
  }

  &__step-selector-number {
    position: absolute;
    top: 50%;
    left: 12px;
    translate: 0 -50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    font-size: 20px;
    font-weight: normal;
    color: var(--number-color, var(--color-black));
    background-color: var(--number-bg-color, var(--color-white));
    border-radius: 50%;
    transition: color .3s ease-out, background-color .3s ease-out;
  }

  &__step-selector-text {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    white-space: nowrap;
  }
}
