@import '@/styles/variables';

.review-settings {
  &__table {
    padding-inline: 20px;
  }

  &__header {
    border-bottom: none;
  }

  &__table-column {
    padding-right: 10px;

    &_right-gap {
      padding-right: 40px;
    }
  }

  &__data-container {
    &_reset-settings {
      width: var(--run-design-cell-column-content-default-max-width);
    }
  }

  &__data-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 94px;
    height: 55px;
    padding: 7px;
    overflow: hidden;
    font-size: var(--run-design-card-inputs-default-font-size);
    text-overflow: ellipsis;
    background-color: var(--color-white);
    border-radius: 10px;

    &_with-icon {
      gap: 20px;
    }

    &_aligned-left{
      justify-content: start;
    }

    &_highlight {
      background-color: var(--color-yellow-60);
    }
  }

  &__text {
    --line-count: 2;
    --line-height: 1.1;

    @include run-design-cell-name;
    display: -webkit-box;
    overflow: hidden;
    line-height: var(--line-height);
    text-align: left;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: var(--line-count);
    -webkit-box-orient: vertical;
  }

  &__reagents {
    display: flex;
    grid-template-columns: 145px 110px 95px;
    gap: 10px;
  }

  &__waves {
    width: 100%;
    font-size: 16px;
  }
}
