.removable {
  width: 100%;
  border: 1px var(--border-style, solid) var(--border-color, transparent);
  border-radius: 8px;
  transition: border .3s ease-out;

  &:has(:global(._select-open)) {
    --border-style: dashed;
    --border-color: var(--border-color-hover, var(--light-color-gray-15));
    --select-border-color: var(--border-color);
  }

  &:hover,
  &:focus-visible,
  &:has(&__delete:focus-visible) {
    --border-color-hover: var(--color-black);
    --backdrop-opacity: 1;
    --backdrop-pointer-events: all;
    --backdrop-inset: -70px;
    --separator-position: 60px;
    --z-index: 2;

    border-color: transparent;
    outline: none;
  }

  &__backdrop {
    position: absolute;
    inset: -1px var(--backdrop-inset, 0) -1px -1px;
    pointer-events: var(--backdrop-pointer-events, none);
    background-color: var(--color-white);
    border: 1px var(--border-style, solid) var(--border-color, transparent);
    border-radius: 10px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.25);
    opacity: var(--backdrop-opacity, 0);
    transition: opacity .3s ease-out, inset .3s ease-out, border .3s ease-out;

    &::before {
      position: absolute;
      top: 10px;
      right: var(--separator-position, 20px);
      bottom: 10px;
      content: '';
      border-left: 1px solid var(--color-black);
      transition: right .3s ease-out;
    }
  }

  &__delete {
    position: absolute;
    top: 50%;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    translate: 0 -50%;

    &:focus-visible {
      outline: 2px solid var(--focus-color);
    }

    &:hover,
    &:active {
      --bg-opacity: 1;
    }

    &::before {
      position: absolute;
      inset: 0;
      content: '';
      background-color: var(--color-yellow-60);
      border-radius: inherit;
      box-shadow: 0 0 0 1px var(--color-black);
      opacity: var(--bg-opacity, 0);
      transition: opacity .3s ease-out;
    }
  }

  &__icon {
    position: relative;
    width: 21px;
    height: 21px;
  }

  & > * {
    z-index: var(--z-index, 1);
  }
}
