@import '@/styles/variables';
@import '@/styles/dashboard';
@import '@/styles/tabs';

@include tabs;

.tabs {
  &__item {
    height:var(--header-height-sm);
    padding: 15px;
  }
}

.dashboard {
  --page-header-height: 70px;
  --page-footer-height: 65px;
  --gap: 20px;

  @include dashboard;
  height: calc(100vh - var(--page-header-height) - var(--page-footer-height) - var(--gap));

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
    padding-top: 20px;
  }

  &__tabs {
    flex-grow: 1;
    overflow: hidden;
  }

  &__tab-panel {
    --header-height-with-paddings: 70px;

    &_selected {
      height: calc(100% - var(--header-height-with-paddings));
    }
  }
}

.content-block {
  position: relative;
  padding: 15px;
  background-color: var(--color-white);
  border-radius: 20px;
}
